<template>
    <div class="fixed-background vh-100">
        <div v-if="this.$root.pin">
            <AccountsDesktop class="my-auto" v-if="!this.$root.isMobile"/>
            <AccountsMobile v-if="this.$root.isMobile"/>
            <vue-snotify></vue-snotify>
        </div>
    </div>
</template>

<script>
import AccountsDesktop from '@/components/accounts/AccountsDesktop';
import AccountsMobile from '@/components/accounts/AccountsMobile';

export default {
    name: "accounts",
    components: {
        AccountsDesktop,
        AccountsMobile,
    },
    async created() {
        if (!this.$root.pin) {
            await this.$router.push('/auth-pages/login');
            return false;
        }
    }
}
</script>

<style scoped>

</style>
