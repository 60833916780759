import { render, staticRenderFns } from "./AccountsDesktop.vue?vue&type=template&id=20da31d6&scoped=true&"
import script from "./AccountsDesktop.vue?vue&type=script&lang=js&"
export * from "./AccountsDesktop.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20da31d6",
  null
  
)

export default component.exports