<template>
    <section class="xbts-create-account">
        <h4 v-show="!$root.isMobile" class="text-center text-white font-weight-light">{{$t('app.create_new_account')}} <i
                class="icon-user-follow float-right"></i></h4>

        <div v-show="step === 1">
            <p>
                <b-form-group :label="$t('app.account') + '(' + $t('app.public') + ')'" label-for="reg_name">
                    <b-form-input :placeholder="$t('app.account')" @input="searchAccount()"
                                  id="reg_name"
                                  type="text"
                                  autocomplete="false"
                                  v-model="reg.name"
                    >
                    </b-form-input>
                </b-form-group>

                <b-form-group :label="$t('app.password')" label-for="reg_key">
                    <b-input-group>
                        <b-form-input id="reg_key" placeholder="Password"
                                      type="text"
                                      readonly
                                      v-model="reg.key">
                        </b-form-input>
                        <b-input-group-append>
                            <b-button style="margin-top:2px;" @click="generatePassword" class="btn btn-icon" title="generate random password"
                                      variant="outline-primary"><i class="mdi mdi-dice-5"></i></b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
                <b-form-group>
                    <b-form-input :placeholder="$t('app.repeat_password')" id="reg_key_repeat"
                                  type="text"
                                  autocomplete="false"
                                  v-model="reg.keyRepeat">
                    </b-form-input>
                </b-form-group>
            </p>

            <div>
                <b-form-checkbox
                    class="small"
                        :unchecked-value="false"
                        :value="true"
                        id="checkbox-1"
                        name="checkbox-1"
                        v-model="check['1']"
                >
                    {{$t('wallet.understand_1')}}
                </b-form-checkbox>

                <b-form-checkbox
                    class="small"
                        :unchecked-value="false"
                        :value="true"
                        id="checkbox-2"
                        name="checkbox-2"
                        v-model="check['2']"
                >
                    {{$t('wallet.understand_2')}}
                </b-form-checkbox>

                <b-form-checkbox
                    class="small"
                        :unchecked-value="false"
                        :value="true"
                        id="checkbox-3"
                        name="checkbox-3"
                        v-model="check['3']"
                >
                    {{$t('wallet.understand_3')}}
                </b-form-checkbox>
            </div>
            <hr v-if="!$root.isMobile"/>

            <b-button :disabled="reg.key !== reg.keyRepeat || reg.isExists"
                      @click="createAccount" class="btn-rounded text-uppercase mt-1" :class="$root.isMobile ? 'w-100' : ''" type="submit"
                      variant="success"
            >
              <i class="icon-user-follow"></i> {{$t('app.create_new_account')}}
            </b-button>

          <hr v-if="!$root.isMobile"/>

            <b-alert class="mt-2 bg-gradient- bg-warning text-dark small" show v-show="reg.isCheap === false && reg.name"
                     variant="warning">
                <i class=" icon-info"></i> {{$t('wallet.war_1')}}
            </b-alert>

            <b-alert class="mt-2 bg-gradient- bg-warning text-dark small" show v-show="reg.isExists === true && reg.name"
                     variant="warning">
                <i class=" icon-info"></i> {{$t('wallet.war_2')}}
            </b-alert>

            <b-alert class="mt-2 bg-gradient- bg-warning text-dark small" show v-show="reg.isLastSym === true"
                     variant="warning">
                <i class=" icon-info"></i> {{$t('wallet.war_3')}}
            </b-alert>
        </div>

        <div v-if="step === 2 && result">
            <p>{{result.status}}</p>
            <table class="table table-striped table-borderless">
                <tbody>
                <tr>
                    <td>name</td>
                    <td>{{account.name}}</td>
                </tr>
                <tr>
                    <td>password</td>
                    <td>{{account.password}}</td>
                </tr>
                <tr>
                    <td>active private key</td>
                    <td>{{account.active.privateKey}}</td>
                </tr>
                </tbody>
            </table>
            <hr/>
            <b-button @click="clean" variant="outline-success">{{$t('app.continue')}}</b-button>
        </div>

    </section>
</template>

<script>
import cryptoRandomString from 'crypto-random-string';

const CoinKey = require('coinkey');
import {Login, ChainValidation} from "bitsharesjs";
import eventBus from '@/plugins/event-bus';

export default {
    name: "Register",
    data() {
        return {
            isProcess: false,
            step: 1,
            user: null,
            key: null,
            check: {
                "1": false,
                "2": false,
                "3": false
            },
            reg: {
                name: null,
                key: null,
                keyRepeat: null,
                search: null,
                isExists: null,
                isValid: false,
                isCheap: null,
                isLastSym: null,
            },
            timer: {
                create: null,
                search: null,
            },
            account: {
                name: null,
                password: null,
                owner: {
                    pubKey: null,
                    privateKey: null,
                },
                active: {
                    pubKey: null,
                    privateKey: null,
                },
                memo: {
                    pubKey: null,
                    privateKey: null,
                },
            },
            result: null,
        }
    },
    methods: {
        async prepareName(s) {
            const p = /[^a-z0-9\-]+/g
            return s.replace(p, '').replace('--', '-');
        },

        async searchAccount() {
            clearTimeout(this.timer.search);
            if (!this.reg.name) {
                return;
            }
            this.reg.name = (await this.prepareName(this.reg.name.toLowerCase()));
            if (!this.reg.name.length) {
                return;
            }


            this.reg.search = [];
            this.reg.isExists = null;
            this.reg.isCheap = null;
            this.reg.isCheap = await this.is_cheap_name(this.reg.name);
            this.reg.isLastSym = this.reg.name.substr(-1) === '-';
            if (this.reg.isLastSym) {
                return;
            }

            this.timer.search = setTimeout(async () => {

                if (this.reg.isCheap) {
                    this.reg.search = await this.$store.dispatch('accounts/searchAccount', {
                        name: this.reg.name,
                        limit: 10,
                    });
                }


                for (let i = 0; i < this.reg.search.length; i++) {
                    if (this.reg.search[i][0] === this.reg.name) {
                        this.reg.isExists = true;
                        break;
                    }
                }

            }, 250);
        },

        async generatePassword() {
            this.reg.keyRepeat = null;
            let privateKeyHex = null;
            //for (let i=0; i < 1000; i++) {
            privateKeyHex = cryptoRandomString(64);
            //}
            let key = new CoinKey(new Buffer(privateKeyHex, 'hex'));
            key.compressed = false;
            this.reg.key = ("P" + key.privateWif).substr(0, 45);
        },

        async createAccount() {
            await this.generateKeys();
            await this.registerAccount();
        },

        async generateKeys() {
            const loginKey = await Login.generateKeys(
                this.reg.name,
                this.reg.key,
                ["owner", "active", "memo"],
                "BTS"
            );

            this.account = {
                "name": this.reg.name,
                "password": this.reg.key,
                "active": {
                    pubKey: loginKey.pubKeys.active,
                    privateKey: loginKey.privKeys.active.toWif(),
                },
                "owner": {
                    pubKey: loginKey.pubKeys.owner,
                    privateKey: loginKey.privKeys.owner.toWif(),
                },
                "memo": {
                    pubKey: loginKey.pubKeys.memo,
                    privateKey: loginKey.privKeys.memo.toWif(),
                }
            };

        },

        async is_cheap_name(account_name) {
            return /[0-9-]/.test(account_name) || !/[aeiouy]/.test(account_name);
        },

        async clean() {
            this.account = {
                name: null,
                password: null,
                owner: {
                    pubKey: null,
                    privateKey: null,
                },
                active: {
                    pubKey: null,
                    privateKey: null,
                },
                memo: {
                    pubKey: null,
                    privateKey: null,
                },
            };
            this.result = null;
            this.reg = {
                name: null,
                key: null,
                keyRepeat: null,
                search: null,
                isExists: null,
                isValid: false,
                isCheap: null,
                isLastSym: null,
            };
            this.check = {
                "1": false,
                "2": false,
                "3": false
            };
            this.step = 1;
        },

        async registerAccount() {
            this.step = 2;
            /**
             name: req.body.account.name,
             owner: req.body.account.owner_key,
             active: req.body.account.active_key,
             memo: req.body.account.memo_key,
             referrer: req.body.account.referrer,
             */
            this.result = await this.$store.dispatch('app/accountRegister', {
                name: this.account.name,
                active_key: this.account.active.pubKey,
                owner_key: this.account.owner.pubKey,
                memo_key: this.account.memo.pubKey,
                referrer: 'xbtsx',
            });


            if (this.result.error) {
                this.$snotify.warning(this.result.error.base[0], {
                    timeout: 5000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                });
                this.step = 1;
            }

            if (this.result.status) {
                if (this.result.status !== 'Account created') {
                    return;
                }
                await eventBus.emit('account:save', {
                    name: this.account.name,
                    key: this.reg.key,
                });

                this.$snotify.success('Account ' + this.account.name + ' Created!', {
                    timeout: 2000,
                    showProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                });

                await this.clean();
            }



        },
    },

    async mounted() {
        await this.generatePassword();
    }
}
</script>

<style scoped>

</style>
