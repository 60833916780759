<template>
    <div class="row vh-100 w-100">
        <div class="mx-auto my-auto w-75">
            <section class="auth-card w-100" style="box-shadow: 1px 1px 0.2em #333;">
                <div class="position-relative image-side">
                    <p class="text-white h3">XBTS {{$t('wallet.wallet')}}</p>
                    <p class="text-white mt-3 mb-3">
                        {{$t('app.pin_msg')}}
                    </p>

                    <div class="row">
                        <div class="col-md-12">
                            <span @click="setLang('zh')" class="flag-icon flag-icon-cn cursor-pointer mr-2"></span>
                            <span @click="setLang('ru')" class="flag-icon flag-icon-ru cursor-pointer mr-2"></span>
                            <span @click="setLang('en')" class="flag-icon flag-icon-gb cursor-pointer"></span>
                        </div>
                    </div>


                    <div class="row h-100">
                        <div class="position-relative my-auto w-100">
                            <p class="mt-4">
                                <b-button :variant="tab === 'accounts' ? 'primary' : 'light'"
                                          @click="tab = 'accounts'"
                                          class="btn btn-rounded btn-fw btn-block text-uppercase"

                                >
                                    {{$t('app.select_account')}} <i
                                        class="icon-people float-right"></i></b-button>
                            </p>

                            <p class="white mt-3">
                                <b-button :variant="tab === 'import' ? 'primary' : 'light'"
                                          @click="tab = 'import'"
                                          class="btn btn-rounded btn-fw btn-block text-uppercase"
                                >
                                    {{$t('app.import_account')}}
                                    <i class="icon-user-following float-right"></i></b-button>
                            </p>


                            <p class="white mt-3">
                                <b-button :variant="tab === 'create' ? 'primary' : 'light'"
                                          @click="tab = 'create'"
                                          class="btn btn-rounded btn-fw btn-block text-uppercase">
                                    {{$t('app.create_new_account')}} <i class="icon-user-follow float-right"></i>
                                </b-button>
                            </p>

                        </div>
                    </div>
                </div>

                <div class="form-side bg-dark h-100" style="min-height:540px;">

                    <!-- Select -->
                    <div v-show="tab === 'accounts'">
                        <h3 class="text-center text-white font-weight-light">{{$t('app.my_accounts')}} <i
                                class="icon-people float-right"></i></h3>
                        <p>
                            <span v-if="!accounts">{{$t('app.no_accounts')}}</span>
                        </p>
                        <p v-if="accounts">
                            <button :disabled="isProcess" @click="openAccount(key)"
                                    class="btn btn-outline-primary btn-rounded m-2"
                                    v-bind:key="key" v-for="(item, key) in accounts">
                                {{key}} {{item.id}} {{item.lifetime_referrer}}
                            </button>
                        </p>
                    </div>

                    <!-- Import -->
                    <div v-show="tab === 'import'">
                        <h3 class="text-center text-white font-weight-light">{{$t('app.import_account')}} <i
                                class="icon-user-following float-right"></i></h3>
                        <div class="row">
                            <div aria-label="Change Login Type" class="btn-group mb-3 mt-3 ml-auto mr-auto"
                                 role="group">
                                <button :class="loginType === 'password' ? 'btn-info' : 'btn-inverse-info'"
                                        @click="loginType = 'password'"
                                        class="btn btn-sm btn-rounded"
                                        type="button">
                                    {{$t('app.password')}}
                                </button>
                                <button :class="loginType === 'key' ? 'btn-info' : 'btn-inverse-info'"
                                        @click="loginType = 'key'"
                                        class="btn btn-sm btn-rounded"
                                        type="button">
                                    {{$t('app.key')}}
                                </button>
                                <!--
                                <button @click="loginType = 'file'" class="btn btn-inverse-info btn-sm btn-rounded"
                                        disabled="true"
                                        type="button">
                                    Binary File
                                </button>
                                -->
                            </div>
                        </div>

                      <form>
                        <p>
                            <b-form-group :label="$t('app.account')" label-for="input1">
                                <b-form-input :placeholder="$t('app.account')" @input="change()" id="input1" type="text"
                                              v-model="user"></b-form-input>
                            </b-form-group>
                            <b-form-group :label="title[loginType]" label-for="input2">
                                <b-form-input :placeholder="title[loginType]" @input="change()" id="input2"
                                              type="text"
                                              v-model="key"></b-form-input>
                            </b-form-group>
                            <b-button :disabled="!account" @click="saveAccount()" class="btn-rounded" type="submit"
                                      variant="success">Import
                            </b-button>
                        </p>
                    </form>
                    </div>

                    <!-- Create -->

                    <div v-show="tab === 'create'">
                        <Register/>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import eventBus from '@/plugins/event-bus';
import Register from '@/components/accounts/Register';

export default {
    name: "AccountsDesktop",
    components: {
        Register,
    },
    data() {
        return {
            timerAcc: null,
            isProcess: false,
            tab: 'import',
            user: null,
            key: null,
            account: null,
            loginType: 'password',
            title: {
                password: "Password",
                key: "Private Key",
                file: "Binary File"
            },
            timer: {
                import: null,
            },
        }
    },
    computed: {
        accounts() {
            return this.$store.getters['accounts/accounts'];
        },
    },
    methods: {
        async loginValidate() {
            this.account = await this.$store.dispatch('accounts/login', {
                user: this.user,
                key: this.key,
                type: this.loginType,
            });
        },
        async change() {
            if (this.user) {
                this.user = this.user.toLowerCase();
            }
            if (this.user && this.key) {
                clearTimeout(this.timer.import);
                this.timer.import = setTimeout(async () => {
                    await this.loginValidate();
                }, 1500);
            }
        },
        async saveAccount() {
            if (this.$root.pin) {
                await this.$store.dispatch('accounts/setAccount', {
                    pin: this.$root.pin,
                    user: this.user,
                    key: this.key,
                    type: this.loginType,
                });
                this.user = null;
                this.key = null;
                this.loginType = 'password';
                this.tab = 'accounts';
            }
        },
        async openAccount(user) {
            this.isProcess = true;
            const account = this.$store.getters['accounts/accounts'][user];

            if (account) {

                const key = await this.$store.dispatch('accounts/accountDecrypt', {
                    pin: this.$root.pin,
                    user: user,
                });

                if (key) {
                    this.$root.account = await this.$store.dispatch('accounts/login', {
                        user: user,
                        key: key,
                        type: account.type
                    });
                }

                //console.log('key', key)
                //console.log('account', this.$root.account)

                if (this.$root.account && key) {
                  clearTimeout(this.timerAcc);
                  this.timerAcc = setTimeout(async () => {
                        /*
                        await this.$store.dispatch('accounts/setCurrentAccount', {
                            user: user,
                            id: this.$root.account.account.id,
                            type: account.type
                        });
                         */

                        await this.$store.dispatch('session/subscribeAccount', user);
                        eventBus.on('account:change', async (data) => {
                            await this.$store.dispatch('accounts/updateBalances', this.$root.account.account.id);
                        });


                        //setTimeout(async () => {
                        //await this.$router.push('/portfolio');
                        this.isProcess = false;
                        //await this.$router.push('/defi-pair/SMARTHOLDEM');
                        await this.$router.push('/wallet');
                        //}, 300);
                    }, 250)
                }

                this.isProcess = false;
            }
        }
    },
    async created() {
        if (this.accounts) {
            this.tab = 'accounts';
        }

        eventBus.on('account:save', async (data) => {
            this.user = data.name;
            this.key = data.key;
            this.loginType = 'password';
            await this.saveAccount();
        });
    }
}
</script>

<style scoped>

</style>
